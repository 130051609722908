import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useAppDispatch } from "../../../../redux-store";
import { FormProvider, useForm } from "react-hook-form";
import { WeekdayNumber } from "../../../../ducks/data/schedules/types";
import { addDriverSchedule } from "../../../../ducks/data/drivers";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import DoraWeekdays from "../../../../Components/Toolkit/DoraWeekdays";
import { UFDoraTextInput } from "../../../../Components/Toolkit/DoraTextInput";
import DoraButton from "../../../../Components/Toolkit/DoraButton";
import { UFDoraTimeRangePicker } from "../../../../Components/Toolkit/DoraTimeRangePicker";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const validationSchema = yup.object({
  weekdays: yup.array().of(yup.number()).min(1).required(),
  absenceNote: yup.string(),
  recurrentTimeInterval: yup
    .string()
    .nullable()
    .test("invalid", (value) => {
      if (!value) {
        return true;
      }
      const [timeStart, timeEnd] = value.split(" - ");
      if (timeEnd && !timeStart) {
        return false;
      }
      if (timeEnd && timeStart) {
        if (timeEnd < timeStart) {
          return false;
        }
      }
      return true;
    }),
});

export const DriverScheduleAddRecurrent = (props: {
  driverId: string;
  isOpen: boolean;
  onTitleClick: () => void;
  onSubmit: () => void;
}) => {
  const { t } = useTranslation("driverSchedule");
  const { driverId, isOpen } = props;
  const [saving, setSaving] = useState(false);
  const dispatch = useAppDispatch();

  const formMethods = useForm<{
    weekdays: WeekdayNumber[];
    absenceNote: string;
    recurrentTimeInterval: string | null;
  }>({
    defaultValues: {
      weekdays: [],
      absenceNote: "",
      recurrentTimeInterval: null,
    },
    resolver: yupResolver(validationSchema),
    reValidateMode: "onChange",
  });

  const {
    getValues,
    setValue,
    watch,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = formMethods;

  const weekdaysWatch = watch("weekdays");

  const onWeekdayClick = (weekday: WeekdayNumber) => {
    const weekdays = getValues("weekdays");
    if (weekdays.includes(weekday)) {
      setValue(
        "weekdays",
        weekdays.filter((w: WeekdayNumber) => w !== weekday)
      );
    } else {
      setValue("weekdays", [...weekdays, weekday]);
    }
  };

  const onSubmit = () => {
    handleSubmit((data) => {
      setSaving(true);
      dispatch(
        addDriverSchedule({
          driverId,
          schedule: {
            type: "RECURRENT",
            endDate: null,
            startDate: null,
            ...data,
          },
        })
      )
        .unwrap()
        .then(() => {
          reset();
          props.onSubmit();
        })
        .finally(() => {
          setSaving(false);
        });
    })();
  };

  return (
    <div>
      <div className="driver-schedule-dialog__section-title">
        <div>{t("addSchedule")}</div>
        <IconButton onClick={props.onTitleClick} style={{ fontSize: 16 }}>
          <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
        </IconButton>
      </div>
      {isOpen && (
        <div className="driver-schedule-dialog__section-content">
          <FormProvider {...formMethods}>
            <div>
              <DoraWeekdays
                hasError={!!errors.weekdays}
                onWeekdayClick={onWeekdayClick}
                activeWeekdays={weekdaysWatch}
              />
            </div>
            <div className="mt-3">
              <div className="flex gap-4 justify-between">
                <div>
                  <div className="driver-schedule-dialog__section-content-text mb-1">
                    {t("time")}
                  </div>
                  <UFDoraTimeRangePicker
                    setValue={setValue}
                    getValues={getValues}
                    error={!!errors.recurrentTimeInterval}
                    name={"recurrentTimeInterval"}
                  />
                </div>
                <div className="flex-grow">
                  <div className="driver-schedule-dialog__section-content-text mb-1">
                    {t("absenceNote")}
                  </div>
                  <UFDoraTextInput
                    register={register("absenceNote")}
                    placeholder={t("absenceNote")}
                  />
                </div>
              </div>
            </div>
            <div className="mt-6 mb-5">
              <DoraButton
                type="submit"
                variant="primary-filled"
                disabled={saving}
                onClick={onSubmit}
              >
                {t("save")}
              </DoraButton>
            </div>
          </FormProvider>
        </div>
      )}
    </div>
  );
};
