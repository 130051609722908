import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DoraButton from "../../Toolkit/DoraButton";
import { Grid } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectRouting } from "../../../ducks/data/routing/selectors";
import { useTranslation } from "react-i18next";
import { setRoutingOptions } from "../../../ducks/data/routing";
import { useAppDispatch } from "../../../redux-store";
import { DoraCheckbox } from "../../Toolkit/DoraCheckbox";
import Radio from "@mui/material/Radio";

const RoutingOptionsWithPopper = () => {
  const { t } = useTranslation(["dispatching", "components"]);
  const dispatch = useAppDispatch();
  const routing = useSelector(selectRouting());
  const allowFerryTravel = useMemo(
    () => !!routing?.allowTravelByFerry,
    [routing]
  );
  const [isSaving, setIsSaving] = useState(false);

  const fastestRoute = useMemo(() => !!routing?.fastestRoute, [routing]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const optionsOpen = Boolean(anchorEl);
  const handleOptionsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const [fastest, setFastest] = useState(routing?.fastestRoute || false);
  const [ferry, setFerry] = useState(routing?.allowTravelByFerry || false);

  if (!routing) {
    return null;
  }

  const onSaveClick = () => {
    setIsSaving(true);
    dispatch(
      setRoutingOptions({
        routeId: routing!.routeId,
        fastestRoute: fastest,
        allowTravelByFerry: ferry,
      })
    )
      .unwrap()
      .then(() => setAnchorEl(null))
      .finally(() => setIsSaving(false));
  };

  return (
    <Grid item xs={6} mb={3}>
      <Popper open={optionsOpen} anchorEl={anchorEl} style={{ zIndex: 3 }}>
        <Paper>
          <DialogContent>
            <div className="flex gap-2 items-center">
              <DoraCheckbox
                style={{ marginLeft: 10 }}
                checked={ferry}
                onChange={() => setFerry(!ferry)}
              />
              <label>{t("dispatching:openRouteView.allowTravelByFerry")}</label>
            </div>
            <div className="flex gap-2 items-center mt-2">
              <Radio
                size="small"
                checked={fastest}
                onClick={() => setFastest(true)}
              />
              <label>{t("dispatching:openRouteView.fastestRoute")}</label>
              <Radio
                size="small"
                checked={!fastest}
                onClick={() => setFastest(false)}
              />
              <label>{t("dispatching:openRouteView.shortestRoute")}</label>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleOptionsClick}
              disabled={isSaving}
            >
              {t("components:buttonLabels.cancel")}
            </Button>
            <Button
              variant="contained"
              onClick={onSaveClick}
              disabled={isSaving}
            >
              {t("components:buttonLabels.confirm")}
            </Button>
          </DialogActions>
        </Paper>
      </Popper>
      <div className="flex gap-4 items-center">
        <div>
          {allowFerryTravel && (
            <div>{t("dispatching:openRouteView.allowTravelByFerry")}</div>
          )}
          <div>
            {fastestRoute
              ? t("dispatching:openRouteView.fastestRoute")
              : t("dispatching:openRouteView.shortestRoute")}
          </div>
        </div>
        <DoraButton onClick={handleOptionsClick} variant={"primary"}>
          {t("dispatching:openRouteView.routingOptions")}
        </DoraButton>
      </div>
    </Grid>
  );
};

export default RoutingOptionsWithPopper;
