import * as t from "io-ts";
import { createDriverTypes } from "dora-contracts";

export const {
  driverT,
  driversT,
  driverScheduleT,
  driverSchedulesT,
  baseDriverScheduleT,
} = createDriverTypes({
  dateTime: t.string,
});

export type Driver = t.TypeOf<typeof driverT>;
export type BaseDriverSchedule = t.TypeOf<typeof baseDriverScheduleT>;
export type DriverSchedule = t.TypeOf<typeof driverScheduleT>;
