import * as yup from "yup";
import { timeRangeMin5MinutesErr } from "./CreateEditShipmentDialog/create-edit-shipment-validation-schema";

const savedLocationValidationSchema = yup.object({
  customName: yup.string().optional(),
  visibleToDrivers: yup.boolean().optional(),
  place: yup.object({
    placeName: yup.string().optional(),
    address: yup.string().optional(),
    country: yup.string().optional(),
    city: yup.string().optional(),
    coord: yup.object({
      lon: yup.number().min(-180).max(180),
      lat: yup.number().min(-180).max(180),
    }),
    postcode: yup.string(),
  }),
  openingTime: yup
    .string()
    .transform((_, value) => (value === " - " ? "" : value))
    .test("invalid", (value) => {
      if (!value) {
        return true;
      }
      const [timeStart, timeEnd] = value.split(" - ");
      if (timeEnd && !timeStart) {
        return false;
      }
      if (timeEnd && timeStart) {
        if (timeEnd < timeStart) {
          return false;
        }
      }
      return true;
    })
    .test(timeRangeMin5MinutesErr, (value) => {
      if (!value) {
        return true;
      }
      const [timeStart, timeEnd] = value.split(" - ");
      if (timeStart && timeEnd) {
        const [startHour, startMinute] = timeStart.split(":");
        const [endHour, endMinute] = timeEnd.split(":");
        const startMins = Number(startHour) * 60 + Number(startMinute);
        const endMins = Number(endHour) * 60 + Number(endMinute);
        return endMins - startMins >= 5;
      }
      return true;
    }),
  contactName: yup.string().optional(),
  contactPhoneNumber: yup.string().optional(),
});

export default savedLocationValidationSchema;
