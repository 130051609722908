import { useSelector } from "react-redux";
import Total from "./Total";
import { Form } from "./types";
import { yupResolver } from "@hookform/resolvers/yup";
import "./IncomeHandler.scss";
import {
  selectPricingAgreementsForClient,
  selectProducts,
} from "../../../../../ducks/data/products/selectors";
import { useL10n } from "../../../../../l10n";
import { useCallback, useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import Decimal from "decimal.js-light";
import { UFDoraSelect } from "../../../../Toolkit/DoraSelect";
import { useTranslation } from "react-i18next";
import { selectUnits } from "../../../../../ducks/data/finance/units/selectors";
import { useElementId, useFeature } from "../../../../../hooks";
import DoraButton from "../../../../Toolkit/DoraButton";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./InvoiceLineRowForm.scss";
import { UFDoraTextInput } from "../../../../Toolkit/DoraTextInput";
import ValidationError from "./ValidationError";
import { TextField } from "@mui/material";

export const InvoiceLineRowForm = (props: {
  createdDate?: string;
  defaultValues: Form;
  onCancel: () => void;
  onSubmit: (form: Form) => Promise<void>;
  skipInvoiceLineSpecificCells?: boolean;
  clientId?: string;
  extraError?: string;
}) => {
  const { t } = useTranslation(["components", "invoicing"]);
  const discountEnabled = useFeature("invoice-line-discount-percentage");
  const {
    defaultValues,
    skipInvoiceLineSpecificCells,
    clientId,
    extraError,
    onSubmit,
    onCancel,
  } = props;
  const l10n = useL10n();
  const schema = useMemo(
    () =>
      yup.object({
        description: yup.string().nullable(),
        productId: yup.string().nullable(),
        unitId: yup.string().nullable(),
        quantity: yup
          .number()
          .required()
          .positive()
          .test("tooManyDecimals", "Must have two digits", (x) => {
            if (!x) {
              return true;
            }
            const d = new Decimal(x);
            return d.eq(d.toDecimalPlaces(2));
          })
          .transform((_, strValue) => l10n.parseNumber(strValue)),
        amount: yup
          .number()
          .required()
          .transform((_, strValue: number) => Math.round(strValue * 100) / 100)
          .transform((_, strValue) => l10n.parseNumber(strValue)),
        discountPercentage: yup
          .number()
          .nullable()
          .transform((_, strValue) => {
            if (strValue === "" || strValue === "0") {
              return null;
            }
            const normalizedValue = strValue.replace(",", ".");

            return new Decimal(normalizedValue).toDecimalPlaces(2).toNumber();
          })
          .min(0.01)
          .max(99.99),
      }),
    [l10n]
  );

  const formMethods = useForm<Form>({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { watch, register, handleSubmit, control, getValues } = formMethods;
  const errors = formMethods.formState.errors;
  const quantityValidationId = useElementId();
  const amountValidationId = useElementId();
  const products = useSelector(selectProducts);
  const pricingAgreements = useSelector(
    selectPricingAgreementsForClient(clientId || "")
  );
  const units = useSelector(selectUnits);

  const inputValidationProps = useCallback(
    (id: string, field: keyof Form) => {
      const fieldError = formMethods.formState.errors[field];
      return fieldError ? { "aria-invalid": true, "aria-describedby": id } : {};
    },
    [formMethods.formState]
  );

  const submitRow = useMemo(() => {
    return handleSubmit((values) =>
      onSubmit(values).catch((err) => {
        console.error(err);
      })
    );
  }, [handleSubmit, onSubmit]);

  useEffect(() => {
    let prevProductId = formMethods.getValues().productId;
    const { unsubscribe } = watch((value, x) => {
      const { name, type } = x;
      if (name === "productId" && type === "change") {
        const prevProduct =
          (prevProductId && products.find((x) => x.id === prevProductId)) ||
          null;
        prevProductId = value.productId || null;
        const product =
          value.productId && products.find((x) => x.id === value.productId);
        if (product) {
          const pricingAgreementForProduct = pricingAgreements.find(
            (pa) => pa.productId === product.id
          );

          const salesPrice =
            pricingAgreementForProduct?.salesPrice || product.salesPrice || "";
          if (salesPrice) {
            if (value.amount) {
              if (
                l10n.parseNumber(value.amount).toString() !==
                prevProduct?.salesPrice
              ) {
                return;
              }
            }
            formMethods.setValue("amount", l10n.formatCurrency(salesPrice));
          }

          const discountPercentage =
            pricingAgreementForProduct?.discountPercentage;
          if (discountPercentage && !value.discountPercentage) {
            formMethods.setValue(
              "discountPercentage",
              l10n.formatNumber(discountPercentage)
            );
          }

          const description =
            pricingAgreementForProduct?.name || product.name || "";
          if ((prevProduct ? prevProduct.name : "") !== value.description) {
            return;
          }
          formMethods.setValue("description", description);

          const unitId =
            pricingAgreementForProduct?.unitId || product.unitId || "";
          if (unitId && !value.unitId) {
            formMethods.setValue("unitId", unitId);
          }

          const unitNumber = pricingAgreementForProduct?.unitNumber || 1;
          if (unitNumber && (!value.quantity || value.quantity === "1")) {
            formMethods.setValue("quantity", l10n.formatNumber(unitNumber));
          }
        }
      }
    });
    return unsubscribe;
  }, [watch, l10n, formMethods, products, pricingAgreements]);

  const options = useMemo(
    () =>
      products.map((product) => {
        const pricingAgreement = pricingAgreements.find(
          (pa) => pa.productId === product.id
        );
        return {
          value: product.id,
          label: `${product.productNumber} ${pricingAgreement?.name || product.name}`,
        };
      }),
    [products, pricingAgreements]
  );

  const productId = getValues("productId");
  const product = useMemo(
    () => (productId ? products.find((x) => x.id === productId) : null),
    [productId, products]
  );

  const unitOptions = useMemo(
    () => (units || []).map((unit) => ({ value: unit.id, label: unit.name })),
    [units]
  );

  return (
    <FormProvider {...formMethods}>
      <form role="row form" className="income-grid__row" onSubmit={submitRow}>
        {!skipInvoiceLineSpecificCells && (
          <div className="add-invoice-line-row__cell" role="gridcell">
            {/* selected */}
          </div>
        )}
        <div className="add-invoice-line-row__cell" role="gridcell">
          <UFDoraSelect
            fullWidth
            autoselect
            aria-labelledby="income-grid-heading-product"
            control={control}
            name="productId"
            customRenderInput={(params) => (
              <TextField
                {...params}
                autoFocus
                name="productId"
                id={"productId"}
                inputProps={{
                  ...params.inputProps,
                  "aria-label": "Code",
                }}
              >
                {product?.name}
              </TextField>
            )}
            options={options}
            autofocus
          />
        </div>
        <div className="add-invoice-line-row__cell" role="gridcell">
          <UFDoraTextInput
            register={register("description")}
            aria-label={t("invoicing:invoiceLine.description")}
          />
        </div>
        <div className="add-invoice-line-row__cell" role="gridcell">
          <UFDoraTextInput
            aria-label={t("invoicing:invoiceLine.quantity")}
            register={register("quantity")}
            {...inputValidationProps(quantityValidationId, "quantity")}
            error={!!errors["quantity"]}
          />
        </div>
        <div className="add-invoice-line-row__cell" role="gridcell">
          <UFDoraSelect
            control={control}
            fullWidth
            autoselect
            name="unitId"
            options={unitOptions}
            aria-labelledby="income-grid-heading-unit"
          />
        </div>
        <div className="add-invoice-line-row__cell" role="gridcell">
          <UFDoraTextInput
            aria-label={t("invoicing:invoiceLine.amount")}
            register={register("amount")}
            error={!!errors["amount"]}
            {...inputValidationProps(amountValidationId, "amount")}
          />
        </div>
        {!skipInvoiceLineSpecificCells && (
          <div className="add-invoice-line-row__cell" role="gridcell"></div>
        )}
        <div className="add-invoice-line-row__cell" role="gridcell">
          {discountEnabled && (
            <UFDoraTextInput
              style={{ maxWidth: "4.5rem" }}
              aria-label={t("invoicing:invoiceLine.discountPercentage")}
              register={register("discountPercentage")}
              error={!!errors["discountPercentage"]}
              {...inputValidationProps(
                amountValidationId,
                "discountPercentage"
              )}
            />
          )}
        </div>
        {!skipInvoiceLineSpecificCells && (
          <>
            <div className="add-invoice-line-row__cell" role="gridcell">
              <div role="gridcell" className="flex items-center">
                {props.createdDate && l10n.formatDate(props.createdDate)}
              </div>
            </div>
          </>
        )}
        <div className="add-invoice-line-row__cell justify-end" role="gridcell">
          <Total />
        </div>
        <div className="add-invoice-line-row__last-cell" role="gridcell">
          <DoraButton
            variant="primary"
            type="button"
            className={"add-invoice-line-last-cell__button"}
            title={t("components:buttonLabels.cancel")}
            aria-label={t("components:buttonLabels.cancel")}
            onClick={onCancel}
          >
            <FontAwesomeIcon icon={faTimes} />
          </DoraButton>
          <DoraButton
            variant="primary"
            className={"add-invoice-line-last-cell__button"}
            type="submit"
            title={t("components:buttonLabels.save")}
            aria-label={t("components:buttonLabels.save")}
          >
            <FontAwesomeIcon icon={faCheck} />
          </DoraButton>
        </div>
      </form>

      {errors["quantity"] || errors["amount"] || extraError ? (
        <div className="income-handler-row__errors">
          {errors["quantity"] ? (
            <ValidationError id={quantityValidationId} field="quantity" />
          ) : null}
          {errors["amount"] ? (
            <ValidationError id={amountValidationId} field="amount" />
          ) : null}
          {extraError && <div>{extraError}</div>}
        </div>
      ) : null}
    </FormProvider>
  );
};

export default InvoiceLineRowForm;
