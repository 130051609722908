import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createErrorReportingAsyncThunk,
  defaultErrorAction,
} from "../../helpers";
import type { Dictionary } from "lodash";
import axios, { getData } from "../../../axios";
import {
  BaseTrailer,
  Trailer,
  decodeTrailer,
  decodeTrailers,
  UpdateTrailer,
} from "./types";
import keyBy from "lodash/keyBy";
import { notifyL } from "../../notifications";
import { FormData } from "../../app/trailer-dialog";
import { ERR_DUPLICATE_VIN } from "dora-shared";

const prefix = "data/trailers";

type State = {
  entities: Dictionary<Trailer>;
  ids: string[];
};

const initialState: State = {
  entities: {},
  ids: [],
};

export const loadTrailers = createErrorReportingAsyncThunk(
  `${prefix}/load`,
  async (): Promise<Trailer[]> => {
    return await axios.get("/api/trailers").then(getData).then(decodeTrailers);
  }
);

export const addTrailer = createAsyncThunk(
  `${prefix}/add`,
  async (trailerValues: FormData, thunkApi): Promise<Trailer | null> => {
    const { volume, type, ...rest } = trailerValues;
    if (!type) {
      throw new Error("Type should have been validated");
    }
    const postData: BaseTrailer = { type, volume: volume || null, ...rest };
    try {
      const res = await axios.post("/api/trailers", postData);
      thunkApi.dispatch(
        notifyL({
          namespace: "notifications",
          key: "trailerSaved",
          type: "success",
        })
      );
      return decodeTrailer(getData(res));
    } catch (err: any) {
      if (err.response?.data?.code === ERR_DUPLICATE_VIN) {
        thunkApi.dispatch(
          notifyL({
            namespace: "notifications",
            key: "errorSavingTrailerDuplicateVIN",
            type: "error",
          })
        );
      } else {
        thunkApi.dispatch(defaultErrorAction());
      }
      throw err;
    }
  }
);

export const updateTrailer = createErrorReportingAsyncThunk(
  `${prefix}/update-trailer`,
  async (trailer: UpdateTrailer, thunkApi) => {
    const result = await axios.put(`/api/trailers/${trailer.id}`, trailer);
    thunkApi.dispatch(
      notifyL({
        namespace: "notifications",
        key: "trailerSaved",
        type: "success",
      })
    );
    return await decodeTrailer(result.data);
  }
);

const slice = createSlice({
  name: prefix,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadTrailers.fulfilled, (state, action) => {
        const trailers = action.payload;
        state.entities = keyBy(trailers, "id");
        state.ids = trailers.map((x) => x.id);
      })
      .addCase(addTrailer.fulfilled, (state, action) => {
        if (!action.payload) {
          return;
        }
        const trailer = action.payload;
        state.ids.unshift(trailer.id);
        state.entities[trailer.id] = trailer;
      })
      .addCase(updateTrailer.fulfilled, (state, action) => {
        const trailer = action.payload;
        state.entities[trailer.id] = trailer;
      });
  },
});

export default slice.reducer;
