import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, IconButton, IconButtonProps } from "@mui/material";
import { CalendarIcon } from "@mui/x-date-pickers";
import { MuiTooltip } from "../../../../Components/nav/nav-styles";
import { useAppDispatch, useSelector } from "../../../../redux-store";
import { selectDriver } from "../../../../ducks/data/drivers/selectors";
import { CloseButton } from "../../../../Components/dispatching-planning/common/close-button";
import DoraButton from "../../../../Components/Toolkit/DoraButton";
import "./DriverScheduleButtonWithDialog.scss";
import {
  loadDrivers,
  deleteDriverSchedule,
} from "../../../../ducks/data/drivers";
import { BaseDriverSchedule } from "../../../../ducks/data/drivers/types";
import { useL10n } from "../../../../l10n";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { DriverScheduleAddRecurrent } from "./DriverScheduleAddRecurrent";
import { DriverScheduleAddInterval } from "./DriverScheduleAddInterval";

export const DriverScheduleButtonWithDialog = (
  props: IconButtonProps & { driverId: string }
) => {
  const { t } = useTranslation("driverSchedule");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { driverId } = props;

  return (
    <>
      {isDialogOpen && (
        <DriverScheduleDialog
          driverId={driverId}
          onClose={() => setIsDialogOpen(false)}
        />
      )}
      <IconButton
        aria-label={t("buttonLabel")}
        onClick={() => setIsDialogOpen(true)}
      >
        <MuiTooltip title={t("buttonLabel")} placement="top">
          <CalendarIcon fontSize="small" />
        </MuiTooltip>
      </IconButton>
    </>
  );
};

const DriverScheduleDialog = (props: {
  driverId: string;
  onClose: () => void;
}) => {
  const { driverId, onClose } = props;
  const { t } = useTranslation("driverSchedule");
  const [isAddIntervalOpen, setIsAddIntervalOpen] = useState(false);
  const [isAddRecurrentOpen, setIsAddRecurrentOpen] = useState(false);
  const dispatch = useAppDispatch();
  const driver = useSelector(selectDriver(driverId));
  if (!driver) {
    return null;
  }
  const driverName = `${driver.firstName} ${driver.lastName.charAt(0)}.`;

  const onAddIntervalClick = () => {
    if (isAddIntervalOpen) {
      setIsAddIntervalOpen(false);
    } else {
      setIsAddIntervalOpen(true);
      setIsAddRecurrentOpen(false);
    }
  };

  const onAddRecurrentClick = () => {
    if (isAddRecurrentOpen) {
      setIsAddRecurrentOpen(false);
    } else {
      setIsAddRecurrentOpen(true);
      setIsAddIntervalOpen(false);
    }
  };

  const onDeleteClick = (scheduleId: string) => {
    dispatch(deleteDriverSchedule({ driverId, scheduleId }))
      .unwrap()
      .then(() => {
        dispatch(loadDrivers());
      });
  };

  return (
    <Dialog open onClose={onClose}>
      <div className="driver-schedule-dialog">
        <div className="driver-schedule-dialog__title-wrapper">
          <div className="driver-schedule-dialog__title">
            {t("title", { driverName })}
          </div>
          <CloseButton onClick={onClose} />
        </div>
        <div className="driver-schedule-dialog__content">
          <div className="driver-schedule-dialog__text">
            <div>{t("currentAbsence")}</div>
            <div className="mt-4">
              {driver.schedules.map((schedule) => (
                <div
                  className="driver-schedule-dialog__schedule"
                  key={schedule.id}
                >
                  <DriverScheduleText schedule={schedule} />
                  <div className="flex gap-2 items-center">
                    <div style={{ maxWidth: 120 }}>{schedule.absenceNote}</div>
                    <DoraButton
                      variant="ghost"
                      style={{ padding: 0 }}
                      onClick={() => onDeleteClick(schedule.id)}
                    >
                      <DeleteOutlinedIcon
                        style={{ color: "var(--error-600)" }}
                      />
                    </DoraButton>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="driver-schedule-dialog__add-interval-section">
          <DriverScheduleAddInterval
            driverId={driverId}
            isOpen={isAddIntervalOpen}
            onTitleClick={onAddIntervalClick}
            onSubmit={() => setIsAddIntervalOpen(false)}
          />
        </div>
        <div>
          <DriverScheduleAddRecurrent
            driverId={driverId}
            isOpen={isAddRecurrentOpen}
            onTitleClick={onAddRecurrentClick}
            onSubmit={() => setIsAddRecurrentOpen(false)}
          />
        </div>
      </div>
    </Dialog>
  );
};

export const DriverScheduleText = ({
  schedule,
}: {
  schedule: BaseDriverSchedule;
}) => {
  const l10n = useL10n();
  const { t } = useTranslation("schedules");
  if (schedule.type === "INTERVAL") {
    return (
      <div style={{ maxWidth: 120 }}>
        {l10n.formatDateAndTime(schedule.startDate!)} -{" "}
        {l10n.formatDateAndTime(schedule.endDate!)}
      </div>
    );
  } else {
    return (
      <div className="flex gap-2 items-center" style={{ maxWidth: 180 }}>
        <div>
          {schedule
            .weekdays!.map((weekday) => t(`weekdaysNames.${weekday}`))
            .join(", ")}
        </div>
        {schedule.recurrentTimeInterval && (
          <div>{schedule.recurrentTimeInterval}</div>
        )}
      </div>
    );
  }
};
