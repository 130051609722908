import * as t from "io-ts";
import { decode } from "io-ts-promise";
import { createTrailerTypes } from "dora-contracts";

const { baseTrailerT, trailerT, trailersT, updateTrailerT } = createTrailerTypes({
  decimal: t.string,
});

export type UpdateTrailer = t.TypeOf<typeof updateTrailerT>;
export type Trailer = t.TypeOf<typeof trailerT>;
export type BaseTrailer = t.TypeOf<typeof baseTrailerT>;
export const decodeTrailers = decode(trailersT);
export const decodeTrailer = decode(trailerT);
