import * as t from "io-ts";
import nullable from "../../../types/nullable";
import { createProductsAndSurcharges, createProductsSurchargesAndAgreements } from "dora-contracts";

export const productsSurchargesAndAgreementsT =
  createProductsSurchargesAndAgreements({
    decimalT: t.string,
  });

export const productsT = t.array(
  t.strict({
    id: t.string,
    productNumber: t.string,
    name: t.string,
    salesPrice: nullable(t.string),
  })
);

export const createProductsAndSurchargesT = createProductsAndSurcharges({
  decimalT: t.string,
});

export type ProductsAndSurcharges = t.TypeOf<typeof createProductsAndSurchargesT>;
