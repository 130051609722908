import { DateTime } from "luxon";
import { Control, Controller } from "react-hook-form";
import { DesktopDateTimePicker } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";

type DatePickerProps = {
  id?: string;
  onChange?: (date: DateTime) => void;
  value?: DateTime;
  label?: string;
  minDateTime?: DateTime;

  // useForms inject
  controlField?: any;
};

type UseFormsInputProps = {
  control: Control<any, any>;
  name: string;
};

export const DoraDateTimePicker = (props: DatePickerProps) => {
  const { controlField, id, onChange, value, label, minDateTime } = props;
  const { t } = useTranslation("components");
  return (
    <DesktopDateTimePicker
      slotProps={{
        inputAdornment: { position: "start" },
        openPickerButton: { tabIndex: -1 },
      }}
      displayWeekNumber
      localeText={{
        calendarWeekNumberHeaderText: t("week"),
      }}
      label={label}
      {...(minDateTime && { minDateTime })}
      {...(id && { id })}
      {...(!controlField && { onChange, value })}
      {...controlField}
    />
  );
};

export const UFDoraDateTimePicker = (
  props: DatePickerProps & UseFormsInputProps
) => {
  const { name, control, ...rest } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <DoraDateTimePicker controlField={field} {...rest} />
      )}
    />
  );
};
