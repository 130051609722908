import axios, { getData } from "../../../axios";
import { createSlice } from "@reduxjs/toolkit";
import {
  Team,
  teamInputT,
  teamsT,
  teamT,
  teamDriversT,
  teamVehiclesT,
} from "dora-contracts";
import { createErrorReportingAsyncThunk } from "../../helpers";
import * as t from "io-ts";
import * as tPromise from "io-ts-promise";

type TeamInput = t.TypeOf<typeof teamInputT>;

type State = {
  teams: Team[];
  teamsDrivers: Record<string, string[]>;
  teamsVehicles: Record<string, string[]>;
};

const initialState: State = { teams: [], teamsDrivers: {}, teamsVehicles: {} };

const prefix = "data/teams";

export const addTeam = createErrorReportingAsyncThunk(
  `${prefix}/add-team`,
  async (team: TeamInput): Promise<Team> => {
    return axios
      .post("/api/teams", team)
      .then(getData)
      .then(tPromise.decode(teamT));
  }
);

export const loadTeams = createErrorReportingAsyncThunk(
  `${prefix}/load-teams`,
  async (): Promise<Team[]> => {
    return axios.get("/api/teams").then(getData).then(tPromise.decode(teamsT));
  }
);

export const loadTeamDrivers = createErrorReportingAsyncThunk(
  `${prefix}/load-team-drivers`,
  async (teamId: string) => {
    return axios
      .get(`/api/teams/${teamId}/drivers`)
      .then(getData)
      .then(tPromise.decode(teamDriversT));
  }
);

export const loadTeamVehicles = createErrorReportingAsyncThunk(
  `${prefix}/load-team-vehicles`,
  async (teamId: string) => {
    return axios
      .get(`/api/teams/${teamId}/vehicles`)
      .then(getData)
      .then(tPromise.decode(teamVehiclesT));
  }
);

export const attachVehicleToTeam = createErrorReportingAsyncThunk(
  `${prefix}/attach-vehicle-to-team`,
  async (
    { teamId, vehicleId }: { teamId: string; vehicleId: string },
    getThunkAPI
  ) => {
    await axios.post(`/api/teams/${teamId}/vehicles/${vehicleId}`);
    const { dispatch } = getThunkAPI;
    dispatch(loadTeamVehicles(teamId));
  }
);

export const detachVehicleFromTeam = createErrorReportingAsyncThunk(
  `${prefix}/detach-vehicle-from-team`,
  async (
    { teamId, vehicleId }: { teamId: string; vehicleId: string },
    getThunkAPI
  ) => {
    await axios.delete(`/api/teams/${teamId}/vehicles/${vehicleId}`);
    const { dispatch } = getThunkAPI;
    dispatch(loadTeamVehicles(teamId));
  }
);

export const attachDriverToTeam = createErrorReportingAsyncThunk(
  `${prefix}/attach-driver-to-team`,
  async (
    { teamId, driverId }: { teamId: string; driverId: string },
    getThunkAPI
  ) => {
    await axios.post(`/api/teams/${teamId}/drivers/${driverId}`);
    const { dispatch } = getThunkAPI;
    dispatch(loadTeamDrivers(teamId));
  }
);

export const detachDriverFromTeam = createErrorReportingAsyncThunk(
  `${prefix}/detach-driver-from-team`,
  async (
    { teamId, driverId }: { teamId: string; driverId: string },
    getThunkAPI
  ) => {
    await axios.delete(`/api/teams/${teamId}/drivers/${driverId}`);
    const { dispatch } = getThunkAPI;
    dispatch(loadTeamDrivers(teamId));
  }
);

const slice = createSlice({
  name: prefix,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addTeam.fulfilled, (state, action) => {
        state.teams.push(action.payload);
      })
      .addCase(loadTeams.fulfilled, (state, action) => {
        state.teams = action.payload;
      })
      .addCase(loadTeamDrivers.fulfilled, (state, action) => {
        state.teamsDrivers[action.meta.arg] = action.payload.map(
          (td) => td.driverId
        );
      })
      .addCase(loadTeamVehicles.fulfilled, (state, action) => {
        state.teamsVehicles[action.meta.arg] = action.payload.map(
          (tv) => tv.trailerId
        );
      });
  },
});

export default slice.reducer;
