import { useCallback, useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import CreateEditShipmentData from "./CreateEditShipmentData";
import { useAppDispatch, useSelector } from "../../../redux-store";
import { addLine } from "../../../ducks/data/deliveries";
import {
  selectPricingAgreementsForClient,
  selectProducts,
} from "../../../ducks/data/products/selectors";
import { notifyL } from "../../../ducks/notifications";

const useCreateInvoiceLinesOnSelectedProducts = (
  formMethods: UseFormReturn<CreateEditShipmentData>,
  initialPrevProductsIds: string[],
  deliveryId: string | null
) => {
  const dispatch = useAppDispatch();
  const products = useSelector(selectProducts);

  const { getValues, watch } = formMethods;

  const clientId = watch("clientId");
  const pricingAgreements = useSelector(
    selectPricingAgreementsForClient(clientId || "")
  );

  const [prevProductsIds, setPrevProductsIds] = useState<string[]>(
    initialPrevProductsIds
  );

  const reactOnProductsChange = useCallback(
    (changes: any) => {
      if (changes.name === "products" && changes.type === "change") {
        const currentProducts = getValues("products");
        const newProducts = currentProducts.filter(
          (p) => !prevProductsIds.find((pId) => pId === p.id)
        );
        // newProducts actually is not full products, but only id and label. TS mismatch
        for (const product of newProducts) {
          const pricingAgreementForProduct = pricingAgreements.find(
            (pa) => pa.productId === product.id
          );
          const actualProduct = products.find((p) => p.id === product.id);
          dispatch(
            addLine({
              productId: product.id,
              amount:
                pricingAgreementForProduct?.salesPrice ||
                actualProduct!.salesPrice ||
                "1",
              quantity:
                pricingAgreementForProduct?.unitNumber?.toString() || "1",
              deliveryId: deliveryId!,
              unitId: pricingAgreementForProduct?.unitId || null,
              description:
                pricingAgreementForProduct?.name || (product as any).label,
              discountPercentage:
                pricingAgreementForProduct?.discountPercentage || null,
            })
          )
            .unwrap()
            .then(() => {
              dispatch(
                notifyL({
                  namespace: "notifications",
                  type: "success",
                  key: "invoiceLineCreated",
                })
              );
            });
        }
        setPrevProductsIds(currentProducts.map((p) => p.id));
      }
    },
    [getValues, prevProductsIds, deliveryId, pricingAgreements, dispatch]
  );

  const run = useCallback(
    (changes: any) => {
      reactOnProductsChange(changes);
    },
    [reactOnProductsChange]
  );

  const [hasNotRunTheFirstTime, setHasNotRunTheFirstTime] = useState(true);

  return useEffect(() => {
    if (hasNotRunTheFirstTime) {
      setHasNotRunTheFirstTime(false);
      return;
    }

    const subscription = watch((_, changes: any) => run(changes));
    return () => subscription.unsubscribe();
  }, [watch, run, hasNotRunTheFirstTime, setHasNotRunTheFirstTime]);
};

export default useCreateInvoiceLinesOnSelectedProducts;
