import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../redux-store";
import { selectRouteViewModel } from "../route-views/selectors";
import { DriverSchedule } from "./types";
import { selectAllCargoViews } from "../cargo-views/selectors";
import { DateTime, Interval } from "luxon";
import { addMinutes } from "dora-shared";
import { selectMyTeams } from "../../auth/selectors";
import { selectFeature } from "../../features/selectors";

export const selectStatus = (state: RootState) => state.data.drivers.status;
export const selectDrivers = createSelector(
  (state: RootState) => state.data.drivers.driverEntities,
  (state: RootState) => state.data.drivers.driverIds,
  (entities, ids) => ids.map((id) => entities[id])
);

export const selectDriver = (id?: string) => (state: RootState) =>
  (id && state.data.drivers.driverEntities[id]) || null;

export const selectSpecificDrivers = (ids: string[]) => (state: RootState) =>
  ids.map((id) => state.data.drivers.driverEntities[id]);

export const selectDriverNos = createSelector(selectDrivers, (drivers) =>
  drivers.map((x) => x.number)
);

export const selectRouteFromToDateTimes = (routeId: string) =>
  createSelector(
    selectRouteViewModel(routeId),
    selectAllCargoViews,
    (routeVM, allCargoViews) => {
      if (!routeVM || !routeVM.cargoOrder.length) {
        return {
          from: null,
          to: null,
        };
      }
      const firstCargoId = routeVM.cargoOrder[0];
      const lastCargoId = routeVM.cargoOrder[routeVM.cargoOrder.length - 1];

      const firstCargo = allCargoViews[firstCargoId];
      const lastCargo = allCargoViews[lastCargoId];

      const firstDate = firstCargo.firstStop.date;
      const firstTimeStart = firstCargo.firstStop.time
        ? firstCargo.firstStop.time.split(" - ")[0]
        : "00:00";
      let firstTimeEnd = firstCargo.firstStop.time?.split(" - ")[1] || null;
      if (!firstTimeEnd) {
        firstTimeEnd = addMinutes(firstTimeStart, 60);
      }

      const lastStop = lastCargo.lastStop?.date
        ? lastCargo.lastStop
        : lastCargo.firstStop;

      const lastDate = lastStop.date;
      const lastTimeParts = lastStop.time ? lastStop.time.split(" - ") : null;

      let lastTime = lastTimeParts
        ? lastTimeParts[1] ||
          (lastTimeParts[0] ? addMinutes(lastTimeParts[0], 60) : null)
        : null;

      if (!lastTime) {
        if (lastDate === firstDate) {
          lastTime = addMinutes(firstTimeEnd, 60);
        } else {
          lastTime = "23:59";
        }
      }

      return {
        from: DateTime.fromISO(`${firstDate}T${firstTimeStart}`),
        to: DateTime.fromISO(`${lastDate}T${lastTime}`),
      } as {
        from: DateTime | null;
        to: DateTime | null;
      };
    }
  );

const selectDriversVisibleForUser = createSelector(
  selectDrivers,
  selectMyTeams,
  selectFeature("teams-trailers-vehicles"),
  (drivers, userTeams, featureEnabled) =>
    featureEnabled
      ? drivers.filter((d) => d.teams.some((t) => userTeams.includes(t)))
      : drivers
);

export const selectAllDriversOrderByHavingConflictingSchedulesWithRoute = (
  routeId: string
) =>
  createSelector(
    (state: RootState) => state,
    selectDriversVisibleForUser,
    (state, drivers) => {
      const driversWithConflictingSchedulesSet = new Set<string>();
      for (const driver of drivers) {
        const { conflictingSchedules } =
          selectDriverSchedulesConflictingWithRoute(driver.id, routeId)(state);
        if (conflictingSchedules.length) {
          driversWithConflictingSchedulesSet.add(driver.id);
        }
      }
      return [
        ...drivers.filter((d) => !driversWithConflictingSchedulesSet.has(d.id)),
        ...drivers.filter((d) => driversWithConflictingSchedulesSet.has(d.id)),
      ];
    }
  );

export const selectDriverSchedulesConflictingWithRoute = (
  driverId: string,
  routeId: string
) =>
  createSelector(
    selectDriver(driverId),
    selectRouteViewModel(routeId),
    selectRouteFromToDateTimes(routeId),
    (driver, route, fromToDateTimes) => {
      const conflictingSchedules: DriverSchedule[] = [];
      const schedulesOnRouteDays: DriverSchedule[] = [];
      if (
        !driver?.schedules.length ||
        !route ||
        !route.startDate ||
        !route.endDate ||
        !fromToDateTimes.from ||
        !fromToDateTimes.to
      ) {
        return {
          conflictingSchedules: [],
          schedulesOnRouteDays,
        };
      }

      const recurrentSchedules = driver.schedules.filter(
        (s) => s.type === "RECURRENT"
      );
      const intervalSchedules = driver.schedules.filter(
        (s) => s.type === "INTERVAL"
      );

      const routeInterval = Interval.fromDateTimes(
        fromToDateTimes.from,
        fromToDateTimes.to
      );
      const routeIntervalDays = routeInterval
        .splitBy({ days: 1 })
        .map((i) => i.start.toISODate());

      const getDay = (date: Date) => {
        let d = date.getDay();
        if (d === 0) {
          d = 7;
        }
        return d;
      };

      // RECURRENT
      if (recurrentSchedules.length) {
        const weekdays: number[] = [];
        for (
          let date = new Date(route.startDate);
          date <= new Date(route.endDate);
          date.setDate(date.getDate() + 1)
        ) {
          let d = getDay(date);
          if (!weekdays.includes(d)) {
            weekdays.push(d);
          }
        }

        for (const schedule of recurrentSchedules) {
          const time = schedule.recurrentTimeInterval;
          if (!time) {
            if (schedule.weekdays!.some((w) => weekdays.includes(w))) {
              conflictingSchedules.push(schedule);
            }
            continue;
          }

          const timeParts = time.split(" - ");
          const startTime = timeParts[0];
          const endTime = timeParts[1] || timeParts[0];

          for (const weekday of schedule.weekdays!) {
            for (
              let date = new Date(route.startDate);
              date <= new Date(route.endDate);
              date.setDate(date.getDate() + 1)
            ) {
              if (getDay(date) === weekday) {
                const startDateTime = DateTime.fromISO(
                  `${date.toISOString().split("T")[0]}T${startTime}`
                );
                const endDateTime = DateTime.fromISO(
                  `${date.toISOString().split("T")[0]}T${endTime}`
                );
                const scheduleInterval = Interval.fromDateTimes(
                  startDateTime,
                  endDateTime
                );

                if (routeInterval.overlaps(scheduleInterval)) {
                  conflictingSchedules.push(schedule);
                  break;
                }

                const scheduleIntervalDays = scheduleInterval
                  .splitBy({ days: 1 })
                  .map((i) => i.start.toISODate());
                if (
                  routeIntervalDays.some((d) =>
                    scheduleIntervalDays.includes(d)
                  )
                ) {
                  schedulesOnRouteDays.push(schedule);
                  break;
                }
              }
            }
          }
        }
      }

      // INTERVAL
      for (const intervalSchedule of intervalSchedules) {
        const interval = Interval.fromDateTimes(
          DateTime.fromISO(intervalSchedule.startDate!),
          DateTime.fromISO(intervalSchedule.endDate!)
        );
        // console.log(
        //   "route start",
        //   routeInterval.start.toISOTime(),
        //   "route end",
        //   routeInterval.end.toISOTime(),
        //   "interval start",
        //   interval.start.toISOTime(),
        //   "interval end",
        //   interval.end.toISOTime()
        // );
        if (routeInterval.overlaps(interval)) {
          conflictingSchedules.push(intervalSchedule);
        }
        const intervalDays = interval
          .splitBy({ days: 1 })
          .map((i) => i.start.toISODate());
        if (routeIntervalDays.some((d) => intervalDays.includes(d))) {
          schedulesOnRouteDays.push(intervalSchedule);
        }
      }

      return { conflictingSchedules, schedulesOnRouteDays };
    }
  );
