import { useEffect } from "react";
import uniq from "lodash/uniq";
import { useAppDispatch, useSelector } from "../../../../redux-store";
import { selectInvoiceLines } from "../../../../ducks/data/deliveries/selectors";
import { SyntheticEvent, useMemo, useState } from "react";
import DoraButton from "../../../Toolkit/DoraButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faFileInvoice,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import EditSurchargeLineModal from "./EditSurchargeLineModal";
import InvoiceDialog from "./InvoiceDialog";
import { useTranslation } from "react-i18next";
import { Alert, Button, Grid } from "@mui/material";
import { syncProducts } from "../../../../ducks/data/products";
import { notifyL } from "../../../../ducks/notifications";
import "./InvoicesNew.scss";
import IncomeHandler from "./IncomeHandler";
import * as unitActions from "../../../../ducks/data/finance/units";
import {
  selectProducts,
  selectSurcharges,
} from "../../../../ducks/data/products/selectors";

type InvoicesProps = {
  deliveryId: string;
  cargoId: string;
  clientId?: string;
  clientContact: string | null;
  clientContactName: string | null;
  invoiceRef: string | null;
  onInvoiceSent: () => void;
};

const Invoices = (props: InvoicesProps) => {
  const { deliveryId, cargoId, onInvoiceSent, clientId } = props;
  const { t } = useTranslation(["shipmentModal", "invoicing"]);
  const dispatch = useAppDispatch();
  const lines = useSelector(selectInvoiceLines(deliveryId));
  const initialLineIds = useMemo(
    () => lines.filter((x) => !x.locked).map((x) => x.id),
    [lines]
  );
  const products = useSelector(selectProducts);
  const surcharges = useSelector(selectSurcharges);
  const [invoiceType, setInvoiceType] = useState<"DIRECT" | "CONSOLIDATED">(
    "DIRECT"
  );
  useEffect(() => {
    dispatch(unitActions.loadFinanceUnits());
  }, [dispatch]);
  const [shouldShowInvoiceDialog, setShouldShowInvoiceDialog] = useState(false);
  const [editSurchargeLineId, setEditSurchargeLineId] = useState<string | null>(
    null
  );
  const [selectedLineIds, setSelectedLineIds] =
    useState<string[]>(initialLineIds);
  const [addNewLine, setAddNewLine] = useState(false);

  useEffect(() => {
    setSelectedLineIds((existing) => {
      if (existing.find((x) => !initialLineIds.includes(x))) {
        return existing.filter((x) => initialLineIds.includes(x));
      } else {
        return existing;
      }
    });
  }, [initialLineIds, setSelectedLineIds]);

  const selectLines = (lineIds: string[]) => {
    setSelectedLineIds((lines) => uniq(lines.concat(lineIds)));
  };

  const onInvoiceDialogClose = (linesInvoiced?: boolean) => {
    if (linesInvoiced) {
      setSelectedLineIds([]);
      onInvoiceSent();
    }
    setShouldShowInvoiceDialog(false);
  };

  const onSyncProductsClick = (e: SyntheticEvent) => {
    e.preventDefault();
    dispatch(syncProducts()).then(() => {
      dispatch(
        notifyL({
          namespace: "invoicing",
          key: "invoicingPage.productsSyncedNotification",
        })
      );
    });
  };

  const unableToInvoice = useMemo(() => {
    const linesWithNoProductIds = selectedLineIds
      .map((id) => lines.find((x) => x.id === id))
      .some((x) => x && x!.status !== "INVOICABLE");
    const linesWithProductIdsNotResolved = selectedLineIds
      .map((id) => lines.find((x) => x.id === id))
      .some(
        (x) =>
          x &&
          !products.find((p) => p.id === x!.productId) &&
          !surcharges.find((p) => p.id === x!.productId)
      );

    return linesWithNoProductIds || linesWithProductIdsNotResolved;
  }, [lines, selectedLineIds, products, surcharges]);

  const addLineClick = () => {
    setAddNewLine(true);
  };

  return (
    <>
      {unableToInvoice && (
        <Grid item xs={12}>
          <Alert severity="warning">
            {t("invoicing:invoicingPage.unableToInvoice")}
            <ul className="cannot-invoice-alert__list">
              <li>{t("invoicing:invoicingPage.unableToInvoiceLine1")}</li>
              <li>{t("invoicing:invoicingPage.unableToInvoiceLine2")}</li>
            </ul>
            <br />
            <Button
              onClick={onSyncProductsClick}
              size="small"
              variant="outlined"
            >
              {t("invoicing:invoicingPage.syncProductsButtonText")}
            </Button>
          </Alert>
        </Grid>
      )}
      <>
        <IncomeHandler
          onCancelNewLine={() => setAddNewLine(false)}
          onLinesCreated={(newLineIds) => {
            selectLines(newLineIds);
            setAddNewLine(false);
            setTimeout(() => {
              setAddNewLine(true);
            }, 100);
          }}
          showAddNewLine={addNewLine}
          deliveryId={deliveryId}
          clientId={clientId}
          selectedLineIds={selectedLineIds}
          onSelectedLinesChange={setSelectedLineIds}
        />
        {selectedLineIds.length ? (
          <div className="shipment-dialog-finances__multiple-selected">
            <div>
              {t("cards.finances.selected", { count: selectedLineIds.length })}
            </div>
            <div className="shipment-dialog-finances__multiple-selected-separator"></div>
            <DoraButton
              variant="primary"
              disabled={unableToInvoice}
              onClick={() => {
                setInvoiceType("DIRECT");
                setShouldShowInvoiceDialog(true);
              }}
            >
              <div className="flex items-center gap-2">
                <FontAwesomeIcon icon={faFileInvoice} />
                <div>{t("cards.finances.invoiceButton")}</div>
              </div>
            </DoraButton>
            <DoraButton
              variant="primary"
              disabled={unableToInvoice}
              onClick={() => {
                setInvoiceType("CONSOLIDATED");
                setShouldShowInvoiceDialog(true);
              }}
            >
              <div className="flex items-center gap-2">
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                <div>{t("cards.finances.sendConsolidatedButton")}</div>
              </div>
            </DoraButton>
            <div className="flex-grow"></div>
            <DoraButton
              variant="ghost"
              className="shipment-dialog-finances__multiple-selected-close"
              onClick={() => setSelectedLineIds([])}
            >
              <FontAwesomeIcon icon={faTimes} />
            </DoraButton>
          </div>
        ) : null}
        <DoraButton
          variant="primary"
          onClick={addLineClick}
          disabled={addNewLine}
        >
          {t("invoicing:invoiceLine.newLine")}
        </DoraButton>
      </>
      {shouldShowInvoiceDialog && (
        <InvoiceDialog
          cargoId={cargoId}
          deliveryId={deliveryId}
          type={invoiceType}
          onClose={onInvoiceDialogClose}
          linesIds={selectedLineIds}
          clientId={clientId}
          clientContact={props.clientContact}
          invoiceRef={props.invoiceRef}
        />
      )}
      {editSurchargeLineId && (
        <EditSurchargeLineModal
          id={{
            deliveryId,
            invoiceLineId: editSurchargeLineId,
          }}
          onClose={() => setEditSurchargeLineId(null)}
        />
      )}
    </>
  );
};

export default Invoices;
