import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Control, Controller } from "react-hook-form";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { DoraCheckbox } from "./DoraCheckbox";
import "./DoraSelect.scss";
import { HTMLAttributes } from "react";

type DoraSelectMultipleOption = {
  id: string;
  label: string;
};

type DoraSelectMultipleProps = {
  id?: string;
  placeholder?: string;
  maxSelections?: number;
  selectedValues?: DoraSelectMultipleOption[];
  options: DoraSelectMultipleOption[];
  onChange?: (value: any) => void;
  loading?: boolean;
  loadingText?: string;
  className?: string;
  disabled?: boolean;
  error?: boolean;
  control?: any;
  customRenderOption?: (
    props: HTMLAttributes<HTMLLIElement>,
    option: DoraSelectMultipleOption,
    { selected }: { selected: boolean }
  ) => JSX.Element;

  // useForms inject
  controlField?: any;
};

type UFSelectMultipleProps = {
  name: string;
  placeholder?: string;
  control: Control<any, any>;
  options: { id: string; label: string }[];
};

export const DoraSelectMultiple = (props: DoraSelectMultipleProps) => {
  const {
    id,
    options,
    loading,
    loadingText,
    selectedValues,
    controlField,
    customRenderOption,
    placeholder,
    maxSelections,
    disabled,
    error,
    className,
  } = props;

  // Ugly hack to make it work properly with useForms
  if (controlField?.value?.length) {
    controlField.value = controlField.value.map((v: any) =>
      options.find((o) => o.id === v.id)
    );
  }
  const useFormsValueProp = controlField ? { value: controlField.value } : {};

  const defaultRenderOption = (
    props: any,
    option: DoraSelectMultipleOption,
    { selected }: { selected: boolean }
  ) => (
    <li {...props} key={option.id}>
      <DoraCheckbox checked={selected} style={{ marginRight: 8 }} />
      {option.label}
    </li>
  );

  const wrapperClasses = classnames(
    "dora-select__wrapper",
    disabled ? "dora-select__wrapper--disabled" : null,
    error ? "dora-select__wrapper--error" : null,
    className
  );

  const filterOptionsMethod = maxSelections
    ? (options: DoraSelectMultipleOption[]) =>
        options.filter((option) => controlField.value.length < maxSelections)
    : null;
  const filterOptions = filterOptionsMethod && {
    filterOptions: filterOptionsMethod,
  };

  return (
    <div className={wrapperClasses}>
      <Autocomplete
        multiple
        size="small"
        disabled={disabled}
        options={options}
        loading={controlField?.loading || loading}
        loadingText={loadingText}
        {...selectedValues && { value: selectedValues }}
        {...filterOptions}
        {...(id && { id })}
        {...useFormsValueProp}
        disableCloseOnSelect
        selectOnFocus
        autoHighlight
        handleHomeEndKeys
        sx={{
          margin: 0,
          background: disabled ? "var(--gray-100)" : "white",
          "& input::placeholder": {
            color: "var(--gray-800)",
            fontWeight: 400,
          },
          "& fieldset": { borderColor: "var(--gray-300)" },
          "& .MuiOutlinedInput-root:not(.Mui-focused):hover fieldset": {
            borderColor: "var(--gray-400) !important",
          },
          ...(!disabled && { "&:hover": { borderColor: "var(--gray-300)" } }),
          "& .MuiFormControl-root.MuiFormControl-marginDense.MuiFormControl-fullWidth.MuiTextField-root":
            {
              margin: "0 !important",
            },
          "& .MuiOutlinedInput-root": { paddingRight: "25px !important" },
        }}
        renderOption={customRenderOption || defaultRenderOption}
        onChange={
          controlField
            ? (_, option) => {
                controlField.onChange(option);
                // props.onChange!(option.map((selected) => selected.id));
              }
            : (_, option) =>
                props.onChange!(option.map((selected) => selected.id))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            margin="dense"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <div className="dora-select__caret">
                  <FontAwesomeIcon icon={faAngleDown} />
                </div>
              ),
            }}
            size="small"
          />
        )}
      />
    </div>
  );
};

export const UFDoraSelectMultiple = (
  props: DoraSelectMultipleProps & UFSelectMultipleProps
) => {
  const { error, name, maxSelections, control, options, customRenderOption } =
    props;

  return (
    <Controller
      control={control}
      render={({ field }) => (
        <DoraSelectMultiple
          error={error}
          maxSelections={maxSelections}
          placeholder={props.placeholder}
          className={props.className}
          disabled={props.disabled}
          id={props.id}
          options={options}
          controlField={field}
          customRenderOption={customRenderOption}
        />
      )}
      name={name}
    />
  );
};
