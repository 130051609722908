import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunkAction } from "../../../redux-store";
import * as dataSelectors from "../../data/drivers/selectors";
import * as dataActions from "../../data/drivers";
import { createErrorReportingAsyncThunk } from "../../helpers";
import { CargoType } from "dora-contracts";

const prefix = "app/driver-dialog";

type InitialData = {
  firstName: string;
  lastName: string;
  phoneNo: string;
  phoneCountryCode: string;
  email: string;
  number: string;
  canAddRoutes: boolean;
  canAddInvoiceRef: boolean;
  canSeeClients: boolean;
  canEditCargos: boolean;
  canAddInvoiceLines: boolean;
  autoArriveDepartOnStopCreate: boolean;
  defaultTrailerId: string;
  defaultLanguage: string;
  defaultCargoType: CargoType | null;
};
type State = {
  initialData: null | InitialData;
  editingDriverId: null | string;
  mode: null | "EDIT" | "CREATE";
};

const initialState: State = {
  initialData: null,
  mode: null,
  editingDriverId: null,
};

export const updateDriver = createErrorReportingAsyncThunk(
  `${prefix}/update-driver`,
  async (data: InitialData & { pin: string }, thunkApi) => {
    const driverId = thunkApi.getState().app.driverDialog.editingDriverId;
    if (!driverId) {
      throw new Error("Cannot update when not editing driver");
    }
    const {
      phoneNo,
      phoneCountryCode,
      pin,
      defaultTrailerId,
      defaultLanguage,
      ...rest
    } = data;
    thunkApi.dispatch(
      dataActions.updateDriver({
        id: driverId,
        defaultTrailerId: defaultTrailerId || null,
        phoneNo: { number: phoneNo, countryCode: phoneCountryCode },
        pin: pin || null,
        defaultLanguage: defaultLanguage,
        ...rest,
      })
    );
  }
);

export const editDriver =
  (driverId: string): AppThunkAction =>
  (dispatch, getState) => {
    const driver = dataSelectors.selectDriver(driverId)(getState());
    if (driver) {
      const {
        phoneNo: { number, countryCode },
      } = driver;
      dispatch(
        slice.actions.editDriver({
          ...driver,
          email: driver.email || "",
          defaultTrailerId: driver.defaultTrailerId || "",
          phoneNo: number,
          phoneCountryCode: countryCode,
          defaultLanguage: driver.defaultLanguage,
          defaultCargoType: driver.defaultCargoType,
        })
      );
    }
  };

export const empty: InitialData = {
  firstName: "",
  lastName: "",
  phoneNo: "",
  phoneCountryCode: "",
  canAddRoutes: false,
  canAddInvoiceRef: false,
  canSeeClients: false,
  canEditCargos: false,
  canAddInvoiceLines: false,
  autoArriveDepartOnStopCreate: false,
  email: "",
  number: "",
  defaultTrailerId: "",
  defaultLanguage: "",
  defaultCargoType: null,
};

const slice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    editDriver: (
      state,
      action: PayloadAction<InitialData & { id: string }>
    ) => {
      state.mode = "EDIT";
      state.editingDriverId = action.payload.id;
      state.initialData = action.payload;
    },
    addDriver: (state) => {
      state.mode = "CREATE";
      state.editingDriverId = null;
      state.initialData = empty;
    },
    close: (state) => {
      state.mode = null;
      state.initialData = null;
    },
  },
});

export default slice.reducer;
export const { addDriver, close } = slice.actions;
