import * as React from "react";
import {
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  Checkbox,
  ListItemText,
} from "@mui/material";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  selectAllDriversOrderByHavingConflictingSchedulesWithRoute,
  selectDriver,
  selectDriverSchedulesConflictingWithRoute,
} from "../../../ducks/data/drivers/selectors";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { DriverScheduleText } from "../../../pages/FleetManagementPage/DriversTab/DriverSchedule/DriverScheduleButtonWithDialog";
import clsx from "clsx";
import "./AddDriver.scss";
import { useFeature } from "../../../hooks";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const DriversList = ({
  routeId,
  onDriverClick,
  routeDrivers,
}: {
  routeId: string;
  onDriverClick: (driverId: string, checked: boolean) => void;
  routeDrivers: string[] | null;
}) => {
  // const drivers = useSelector(selectDrivers);
  const drivers = useSelector(
    selectAllDriversOrderByHavingConflictingSchedulesWithRoute(routeId)
  );

  return (
    <>
      {drivers
        .filter((x) => x.active)
        .map((driver) => (
          <DriverMenuItem
            key={driver.id}
            routeId={routeId}
            driverId={driver.id}
            checked={routeDrivers?.includes(driver.id) || false}
            onDriverClick={onDriverClick}
          />
        ))}
    </>
  );
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    border: "1px solid #dadde9",
  },
}));

const DriverMenuItem = (props: {
  routeId: string;
  driverId: string;
  checked: boolean;
  onDriverClick: (driverId: string, checked: boolean) => void;
}) => {
  const { routeId, driverId, checked, onDriverClick } = props;
  const { t } = useTranslation("dispatching");
  const driversSchedulesEnabled = useFeature("drivers-schedules");
  const driver = useSelector(selectDriver(driverId));
  const { conflictingSchedules, schedulesOnRouteDays } = useSelector(
    selectDriverSchedulesConflictingWithRoute(driverId, routeId)
  );
  if (!driver) {
    return null;
  }
  const labelId = `checkbox-list-label-${routeId}-${driver.id}`;
  const classes = clsx("add-driver__menu-item", {
    "add-driver__menu-item--days-intersect":
      !conflictingSchedules.length && schedulesOnRouteDays.length,
    "add-driver__menu-item--conflicting": conflictingSchedules.length,
  });

  const baseElement = (
    <MenuItem
      key={driver.id}
      onClick={() => onDriverClick(driver.id, !checked)}
      className={classes}
    >
      <Checkbox
        icon={icon}
        checkedIcon={checkedIcon}
        style={{ marginRight: 8 }}
        checked={checked}
        inputProps={{ "aria-labelledby": labelId }}
      />
      <ListItemText
        id={labelId}
        primary={`${driver.firstName} ${driver.lastName}`}
        secondary={`${t("routeCard.driverNumber")} ${driver.number}`} // at some point a driver should be connected to a department, and this could potentially be shown here
      />
    </MenuItem>
  );

  let arrayToDisplayInTooltip = conflictingSchedules.length
    ? conflictingSchedules
    : schedulesOnRouteDays.length
      ? schedulesOnRouteDays
      : null;

  return arrayToDisplayInTooltip && driversSchedulesEnabled ? (
    <HtmlTooltip
      title={
        <div className="flex flex-col gap-2">
          {arrayToDisplayInTooltip.map((schedule) => (
            <div className="add-driver__conflicting-schedule" key={schedule.id}>
              {schedule.absenceNote ? (
                <div className="add-driver__conflicting-schedule-header">
                  {schedule.absenceNote}
                </div>
              ) : null}
              <DriverScheduleText schedule={schedule} />
            </div>
          ))}
        </div>
      }
      placement="right"
    >
      {baseElement}
    </HtmlTooltip>
  ) : (
    baseElement
  );
};

const AddDrivers = (props: {
  routeId: string;
  onDriverClick: (driverId: string, checked: boolean) => void;
  routeDrivers: string[] | null;
}) => {
  const { routeId, routeDrivers } = props;
  const [open, setOpen] = React.useState(false);
  const ref = React.useRef<HTMLButtonElement | null>(null);

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onDriverClick = (driverId: string, checked: boolean) => {
    props.onDriverClick(driverId, checked);
    setOpen(false);
  };

  const { t } = useTranslation("dispatching");
  return (
    <div>
      <Tooltip title={t("routeCard.addDriver")}>
        <IconButton
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          ref={ref}
          sx={{ padding: 0 }}
        >
          <PersonAddAlt1Icon fontSize="small" />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={ref.current}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "24ch",
          },
        }}
      >
        <DriversList
          onDriverClick={onDriverClick}
          routeId={routeId}
          routeDrivers={routeDrivers}
        />
      </Menu>
    </div>
  );
};

export default AddDrivers;
