import classnames from "classnames";
import { HTMLAttributes, useState } from "react";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  MenuItem,
  TextField,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import "./DoraSelect.scss";
import { useElementId } from "../../hooks";

export type DoraSelectOption = {
  value: string | null;
  label: string;
  unselectable?: boolean;
};

type SelectProps = {
  style?: any;
  id?: string;
  icon?: IconProp;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  fullWidth?: boolean;
  value?: string;
  options: DoraSelectOption[];
  onChange?: (e: any) => void;
  error?: boolean;
  freeSolo?: boolean;
  autofocus?: boolean;
  autoselect?: boolean;
  onInputChange?: (e: any, v: any) => void;
  customRenderOption?: (
    props: HTMLAttributes<HTMLLIElement>,
    option: DoraSelectOption
  ) => JSX.Element;
  customRenderInput?: (params: AutocompleteRenderInputParams) => JSX.Element;
  customGetOptionLabel?: (option: any) => string;
  label?: string;

  // useForms inject
  controlField?: any;
};

type UseFormsSelectProps = {
  name: string;
  control: Control<any, any>;
};

const setInputWidth = (text: string, hasIcon?: boolean) => {
  return 65 + text.length * 9 + (hasIcon ? 20 : 0);
};

const DoraSelect = (props: SelectProps) => {
  const {
    autofocus,
    autoselect,
    id,
    icon,
    options,
    placeholder,
    fullWidth,
    className,
    freeSolo,
    disabled,
    controlField,
    onInputChange,
    onChange,
    error,
    value,
    customRenderOption,
    customRenderInput,
    customGetOptionLabel,
    label,
    ...rest
  } = props;
  const { t } = useTranslation("components");

  const [length, setLength] = useState(
    placeholder ? setInputWidth(placeholder) : 120
  );

  const wrapperClasses = classnames(
    "dora-select__wrapper",
    disabled ? "dora-select__wrapper--disabled" : null,
    error ? "dora-select__wrapper--error" : null,
    className
  );
  const staticId = useElementId();
  const customId = id || staticId;

  const onOptionSelected = (v: DoraSelectOption | null) => {
    if (onChange) {
      onChange(v?.value);
    }
    setLength(setInputWidth(v?.label || "", !!icon));
  };

  const onAdornmentClick = () => {
    const input = document.getElementById(customId);
    input?.parentElement?.click();
  };

  const valueProp = controlField
    ? {
        value:
          options.find((option) => option.value === controlField.value) || null,
      }
    : {
        inputValue:
          options.find((option) => option.value === value)?.label || value,
      };

  const defaultGetOptionLabel = (option: DoraSelectOption) =>
    option?.label || "";
  const textFieldId = useElementId();

  const defaultRenderOption = (
    props: HTMLAttributes<HTMLLIElement>,
    option: DoraSelectOption
  ) => {
    return (
      <MenuItem {...props} key={option.value} disabled={option.unselectable}>
        {option.label}
      </MenuItem>
    );
  };

  const defaultRenderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      id={textFieldId}
      placeholder={placeholder}
      label={label}
      autoFocus={autofocus}
      sx={
        fullWidth
          ? {}
          : {
              width: `${length}px !important`,
              minWidth: `180px`,
            }
      }
      inputProps={{
        ...params.inputProps,
        ...rest,
      }}
      InputProps={{
        ...params.InputProps,
        sx: { "& .MuiInputBase-input": { textIndent: icon ? 20 : 0 } },
        startAdornment: icon ? (
          <div className="dora-select__icon" onClick={onAdornmentClick}>
            <FontAwesomeIcon icon={icon} />
          </div>
        ) : null,
        endAdornment: (
          <div className="dora-select__caret" onClick={onAdornmentClick}>
            <FontAwesomeIcon icon={faAngleDown} />
          </div>
        ),
      }}
    />
  );

  const actualOptions = options.length
    ? options
    : [{ value: "", label: t("optionLabels.noOptions"), unselectable: true }];

  return (
    <FormControl size="small" className={wrapperClasses}>
      <Autocomplete
        style={props.style || {}}
        fullWidth
        disableClearable
        autoSelect={autoselect}
        autoHighlight
        componentsProps={{
          popper: {
            sx: {
              minWidth: "fit-content",
            },
          },
        }}
        sx={{
          margin: 0,
          background: disabled ? "var(--gray-100)" : "white",
          "& input::placeholder": {
            color: "var(--gray-800)",
            fontWeight: 400,
          },
          "& fieldset": { borderColor: "var(--gray-300)" },
          "& .MuiOutlinedInput-root:not(.Mui-focused):hover fieldset": {
            borderColor: "var(--gray-400) !important",
          },
          ...(!disabled && { "&:hover": { borderColor: "var(--gray-300)" } }),
          "& .MuiFormControl-root.MuiFormControl-marginDense.MuiFormControl-fullWidth.MuiTextField-root":
            {
              margin: "0 !important",
              ...(fullWidth && { width: "100% !important" }),
            },
          "& .MuiFormControl-root": fullWidth
            ? { width: "100% !important" }
            : {},
          "& .MuiOutlinedInput-root": { paddingRight: "25px !important" },
        }}
        options={actualOptions}
        getOptionLabel={customGetOptionLabel || defaultGetOptionLabel}
        renderOption={customRenderOption || defaultRenderOption}
        renderInput={customRenderInput || defaultRenderInput}
        disabled={disabled}
        id={customId}
        {...(freeSolo && { freeSolo })}
        {...controlField}
        {...valueProp}
        {...(onInputChange && { onInputChange })}
        onChange={
          controlField
            ? (_, v: DoraSelectOption) => {
                controlField.onChange(v.value);
                onOptionSelected(v);
              }
            : (_, v) => onOptionSelected(v)
        }
      />
    </FormControl>
  );
};

const UFDoraSelect = (props: SelectProps & UseFormsSelectProps) => {
  const { control, name, ...rest } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <DoraSelect controlField={field} {...rest} />}
    />
  );
};

export { DoraSelect, UFDoraSelect };
