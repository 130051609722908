import { useCallback, useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import CreateEditShipmentData from "./CreateEditShipmentData";
import CreateShipmentData from "./CreateEditShipmentData";
import { getEstimatedDistanceForCoords } from "../../../api";

const useCalculateEstimatedShipmentDistance = (
  formMethods: UseFormReturn<CreateEditShipmentData>,
  stopsCoords: { lat: number; lon: number }[],
  setStopsCoords: (coords: { lat: number; lon: number }[]) => void,
  setEstimatedDistance: (distance: number | null) => void
) => {
  const { getValues, watch } = formMethods;

  const reactOnStopsLocationsChange = useCallback(
    async (values: CreateShipmentData) => {
      const stopsCoordsInOrder = [...values.pickupList, ...values.dropoffList]
        .map((s) => s.place?.coord)
        .filter((c) => c);
      if (JSON.stringify(stopsCoords) !== JSON.stringify(stopsCoordsInOrder)) {
        setStopsCoords(stopsCoordsInOrder as { lat: number; lon: number }[]);
        if (stopsCoordsInOrder.length < 2) {
          setEstimatedDistance(null);
          return;
        }
        const res = await getEstimatedDistanceForCoords(
          stopsCoordsInOrder as { lat: number; lon: number }[]
        );
        console.log('QQQQ')
        setEstimatedDistance(res.data.km);
      }
    },
    [stopsCoords, setEstimatedDistance, setStopsCoords]
  );

  const run = useCallback(
    (_: any) => {
      const values = getValues();
      reactOnStopsLocationsChange(values);
    },
    [getValues, reactOnStopsLocationsChange]
  );

  return useEffect(() => {
    const subscription = watch((_, changes: any) => run(changes));
    return () => subscription.unsubscribe();
  }, [watch, run]);
};

export default useCalculateEstimatedShipmentDistance;
