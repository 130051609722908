import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { WeekdayNumber } from "../../ducks/data/schedules/types";
import "./DoraWeekdays.scss";

const weekdays: WeekdayNumber[] = [1, 2, 3, 4, 5, 6, 7] as const;

const DoraWeekdays = (props: {
  hasError: boolean;
  onWeekdayClick: (weekday: WeekdayNumber) => void;
  activeWeekdays: any[];
}) => {
  const { t } = useTranslation("schedules");
  const { hasError, activeWeekdays, onWeekdayClick } = props;
  const classes = clsx("dora-weekdays", { "dora-weekdays--error": hasError });

  return (
    <div className={classes}>
      {weekdays.map((weekday) => (
        <button
          key={weekday}
          className={clsx("schedule-weekdays__day", {
            active: activeWeekdays.includes(weekday),
          })}
          onClick={() => onWeekdayClick(weekday)}
        >
          {t(`weekdaysNames.${weekday}`)}
        </button>
      ))}
    </div>
  );
};

export default DoraWeekdays;
