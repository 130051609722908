import { Button, Dialog, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CloseButton } from "../../../dispatching-planning/common/close-button";
import "./SaveFavoriteLocationDialog.scss";
import { useActions } from "../../../../hooks/useActions";
import { createSavedLocation } from "../../../../ducks/data/saved-locations";
import { SearchOption } from "./PlaceSearchWithSavedLocations";
import { AutoSuggestItem } from "dora-shared";
import { AddressChange } from "../NewPlaceSearch";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import savedLocationValidationSchema from "../../saved-location-validation-schema";
import { UFDoraTextInput } from "../../../Toolkit/DoraTextInput";
import { UFDoraTimeRangePicker } from "../../../Toolkit/DoraTimeRangePicker";
import { UFDoraCheckbox } from "../../../Toolkit/DoraCheckbox";
import { useFeature } from "../../../../hooks";
import { useEffect } from "react";

const SaveFavoriteLocationDialog = ({
  onClose,
  location,
}: {
  onClose: ({
    placeSaved,
    customName,
    place,
  }: {
    placeSaved: boolean;
    customName?: string;
    place?: AddressChange;
  }) => void;
  location: SearchOption;
}) => {
  const { t } = useTranslation(["modals", "components", "shipmentModal"]);
  const savedLocationsVisibleToDriversEnabled = useFeature(
    "saved-locations-visible-to-drivers"
  );
  const liteVersion = useFeature("lite-version");

  const actions = useActions({
    createSavedLocation,
  });

  const formMethods = useForm({
    resolver: yupResolver(savedLocationValidationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      customName: "",
      visibleToDrivers: false,
      place: location.value.data,
      openingTime: "",
      contactName: "",
      contactPhoneNumber: "",
    },
  });

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isValid },
  } = formMethods;

  useEffect(() => {
    (location.value.data as AutoSuggestItem).lookup().then((lookupResult) => {
      setValue("place", {
        placeName: lookupResult.placeName || "",
        address: lookupResult.address || "",
        country: lookupResult.countryCode || "",
        city: lookupResult.city || "",
        postcode: lookupResult.postcode || "",
        googlePlaceId: lookupResult.googlePlaceId,
        coord: {
          lon: lookupResult.coords.lon,
          lat: lookupResult.coords.lat,
        },
      });
    });
  }, [setValue, location]);

  const onSaveClick = () => {
    const values = getValues();
    handleSubmit(async () => {
      const placeObj: AddressChange = values.place;
      if (values.customName) {
        placeObj.placeName = values.customName;
      }
      await actions.createSavedLocation({
        customName:
          values.customName || placeObj.placeName || placeObj.address || "",
        place: placeObj,
        type: "FAVORITE",
        visibleToDrivers: values.visibleToDrivers,
        openingTime: values.openingTime,
        contactName: values.contactName,
        contactPhoneNumber: values.contactPhoneNumber,
      });
      onClose({
        placeSaved: true,
        ...(values.customName && { customName: values.customName }),
        place: placeObj,
      });
    })();
  };

  return (
    <Dialog open onClose={() => onClose({ placeSaved: false })}>
      <FormProvider {...formMethods}>
        <div className="save-favorite-location__first-row">
          <div>{t("modals:saveLocation.addName")}</div>
          <CloseButton onClick={() => onClose({ placeSaved: false })} />
        </div>
        <div className="save-favorite-location__second-row">
          <TextField
            label={t("modals:saveLocation.addName")}
            {...register("customName")}
          />
          <div className="save-favorite-location__subtext">
            {t("modals:saveLocation.placeNameSubtext")}
          </div>
        </div>
        <div className="save-favorite-location__rest-of-form">
          <div className="add-edit-favorites-terminals__form-row">
            <TextField
              InputLabelProps={{ shrink: true }}
              label={t("shipmentModal:inputs.searchPlaceAddressPlaceholder")}
              {...register("place.address")}
            ></TextField>
          </div>
          <div className="add-edit-favorites-terminals__form-row">
            <TextField
              InputLabelProps={{ shrink: true }}
              label={t("shipmentModal:inputs.searchPlaceCityPlaceholder")}
              className={"add-edit-favorites-terminals__city-input"}
              {...register("place.city")}
            ></TextField>
            <TextField
              InputLabelProps={{ shrink: true }}
              label={t("shipmentModal:inputs.searchPlaceZipPlaceholder")}
              className={"add-edit-favorites-terminals__postal-code-input"}
              {...register("place.postcode")}
            ></TextField>
          </div>
          <div className="add-edit-favorites-terminals__form-row">
            <TextField
              InputLabelProps={{ shrink: true }}
              label={t("shipmentModal:inputs.searchPlaceCountryPlaceholder")}
              {...register("place.country")}
            ></TextField>
          </div>
          <div className="flex justify-between gap-2 mt-1">
            <div className="flex-grow">
              <UFDoraTextInput
                register={register("contactName")}
                placeholder={t("shipmentModal:inputs.contactPlaceholder2")}
              />
            </div>
            <div className="flex-grow">
              <UFDoraTextInput
                register={register("contactPhoneNumber")}
                placeholder={t("shipmentModal:inputs.phoneLabel")}
              />
            </div>
          </div>
          <div className="add-edit-favorites-terminals__form-row gap-2 mt-2 mb-5">
            <div
              className="flex items-center justify-between"
              style={{ width: "50%" }}
            >
              <div style={{ color: "gray", margin: "0 4px" }}>
                {t("shipmentModal:inputs.openingTimeLabel")}
              </div>
              <div style={{ maxWidth: 220, margin: "0 4px" }}>
                <UFDoraTimeRangePicker
                  name={"openingTime"}
                  setValue={setValue}
                  getValues={getValues}
                  error={!!errors.openingTime}
                />
              </div>
            </div>
          </div>
          {!liteVersion && savedLocationsVisibleToDriversEnabled && (
            <div className="add-edit-favorites-terminals__form-row mt-2">
              <div className="flex gap-2 items-center">
                <UFDoraCheckbox register={register("visibleToDrivers")} />
                {t("modals:manageSavedLocations.visibleToDrivers")}
              </div>
            </div>
          )}
        </div>

        <hr className="save-favorite-location__hr" />
        <div className="save-favorite-location__buttons">
          <Button
            variant="outlined"
            color="primary"
            onClick={() => onClose({ placeSaved: false })}
          >
            {t("components:buttonLabels.cancel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!isValid}
            onClick={onSaveClick}
          >
            {t("components:buttonLabels.save")}
          </Button>
        </div>
      </FormProvider>
    </Dialog>
  );
};

export default SaveFavoriteLocationDialog;
