import * as t from "io-ts";
import * as reduxStore from "../../../redux-store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createErrorReportingAsyncThunk } from "../../helpers";
import type { Dictionary } from "lodash";
import axios, { getData } from "../../../axios";
import keyBy from "lodash/keyBy";
import { flespiTokenT, TrackerDevice, trackerDevicesT } from "dora-contracts";
import { decode } from "io-ts-promise";
import { reverseGeoCode } from "../../../helpers/google-helpers";
import { DateTime } from "luxon";
import * as dependencies from "./dependencies";
import {
  selectFlespiDeviceIdFromDoraDeviceId,
  selectTelemetryPosition,
} from "./selectors";
import { AppThunkAction } from "../../../redux-store";
import { flespiTelemetryMessageReceivedForLiveViewRouteTruck } from "../live-view";

const prefix = "data/trackerDevices";

// export type FlespiMqttMessage = {
//   ident: string;
//   "position.latitude": number;
//   "position.longitude": number;
//   "server.timestamp": number;
//   timestamp: number;
//   place: string;
// };

export type TachoDriverStatus = {
  showStatus: boolean;
  continuousDrivingDuration?: number;
  cumulativeBreakDuration?: number;
  cumulativeDrivingDuration?: number;
  selectedActivityDuration?: number;
  timeState?: string;
  workState?: string;
};

type TrackerDeviceTelemetry = {
  id: string;
  position: { lat: number; lon: number };
  ignitionStatus?: { value: boolean };
  movementStatus?: { value: boolean };
  tachoStats?: {
    vehicleMileage?: string;
    driver1: TachoDriverStatus;
    driver2: TachoDriverStatus;
  };
  location?: {
    updatedAt: DateTime;
    place: {
      placeName: string;
      address: string;
      postcode: string;
      city: string;
      country: string;
      coords: { lat: number; lon: number };
      googlePlaceId: string;
    };
  };
};

type State = {
  entities: Dictionary<TrackerDevice>;
  ids: string[];
  // devicesData: Dictionary<FlespiMqttMessage>;
  telemetry: Dictionary<TrackerDeviceTelemetry>;
  token: null | {
    token: string;
    expireAt: string;
  };
};

const initialState: State = {
  entities: {},
  ids: [],
  // devicesData: {},
  telemetry: {},
  token: null,
};

export const loadTrackerDevices = createErrorReportingAsyncThunk(
  `${prefix}/load`,
  async (): Promise<TrackerDevice[]> => {
    return await axios
      .get("/api/tracker-devices")
      .then(getData)
      .then(decode(trackerDevicesT))
      .then((x) => x.data);
  }
);

export const loadDataFromTrackerDevice = createErrorReportingAsyncThunk(
  `${prefix}/loadDataFromTrackerDevice`,
  async (flespiMqttMessage: any) => {
    const reverseGeoCodingResult = await reverseGeoCode({
      lon: flespiMqttMessage["position.longitude"],
      lat: flespiMqttMessage["position.latitude"],
    });
    return {
      ...flespiMqttMessage,
      place: reverseGeoCodingResult.place,
    };
  }
);

export const lookupPositionForAllDevices =
  (): reduxStore.AppThunkAction => (dispatch, getState) => {
    const deviceIds = getState().data.trackerDevices.ids;
    return Promise.all(
      deviceIds.map((deviceId) => dispatch(lookupDevicePosition(deviceId)))
    );
  };

export const lookupDevicePosition =
  (doraDeviceId: string): AppThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    const position = selectTelemetryPosition(doraDeviceId)(state);

    if (!position) {
      return;
    }
    const flespiId = selectFlespiDeviceIdFromDoraDeviceId(doraDeviceId)(state);
    if (!flespiId) {
      return;
    }

    await dispatch(lookupDevicePositionWithTimestamp({ flespiId, position }));
  };

export const lookupDevicePositionWithTimestamp = createErrorReportingAsyncThunk(
  `${prefix}/lookupDevicePositionWithTimestamp`,
  async ({
    flespiId,
    position,
  }: {
    flespiId: number;
    position: { lat: number; lon: number };
  }) => {
    const timestamp = DateTime.utc();
    const place = await dependencies.reverseGeocode(position);
    return { timestamp, flespiId, place };
  }
);

export const loadFlespiToken = createErrorReportingAsyncThunk(
  `${prefix}/loadFlespiToken`,
  async () =>
    axios
      .get("/auth/flespi-token")
      .then(getData)
      .then(decode(t.union([flespiTokenT, t.null])))
);

const telemetryRegex =
  /^flespi\/state\/gw\/devices\/(?<deviceId>\d+)\/telemetry\/(?<key>.+)$/;

export const flespiTelemetryMessageReceived =
  ({ topic, message }: { topic: string; message: any }): AppThunkAction =>
  (dispatch, getState) => {
    const matches = telemetryRegex.exec(topic);
    if (matches) {
      const flespiDeviceId = matches.groups!["deviceId"];
      switch (matches.groups!["key"]) {
        case "position":
          const pos = {
            position: {
              lat: message.latitude,
              lon: message.longitude,
            },
            flespiDeviceId,
          };
          const state = getState();
          dispatch(slice.actions.updateTelemetryPosition(pos));

          const trackerDevice = Object.values(
            state.data.trackerDevices.entities
          ).find((d) => d.flespiId === Number(flespiDeviceId));
          const truck = Object.values(state.data.trailers.entities).find(
            (t) => t.trackerDeviceId === trackerDevice?.id
          );
          if (
            truck &&
            state.data.liveView.routeForLiveView?.vehicle.truckId === truck.id
          ) {
            dispatch(
              flespiTelemetryMessageReceivedForLiveViewRouteTruck({
                message: pos.position,
              })
            );
          }

          const deviceTelemetry =
            state.data.trackerDevices.telemetry[flespiDeviceId];
          // if (
          //   !deviceTelemetry ||
          //   (deviceTelemetry.location?.updatedAt &&
          //     Math.abs(deviceTelemetry.location?.updatedAt.diffNow().as("minutes")) > 2)
          // ) {
          if (!deviceTelemetry?.location) {
            dispatch(
              lookupDevicePositionWithTimestamp({
                flespiId: parseInt(pos.flespiDeviceId, 10),
                position: pos.position,
              })
            );
          }
          break;
        case "engine.ignition.status":
          dispatch(
            slice.actions.updateTelemetryIgnitionStatus({
              flespiDeviceId,
              value: message,
            })
          );
          break;
        case "movement.status":
          dispatch(
            slice.actions.updateTelemetryMovementStatus({
              flespiDeviceId,
              value: message,
            })
          );
          break;
        case "tacho.vehicle.mileage":
          dispatch(
            slice.actions.updateTelemetryTachoAttributesStatus({
              flespiDeviceId,
              value: { vehicleMileage: message },
            })
          );
          break;
        case "tacho.driver.card.status.1":
          dispatch(
            slice.actions.updateTelemetryTachoAttributesStatus({
              flespiDeviceId,
              value: { driver1Status: message },
            })
          );
          break;
        case "tacho.driver.card.status.2":
          dispatch(
            slice.actions.updateTelemetryTachoAttributesStatus({
              flespiDeviceId,
              value: { driver2Status: message },
            })
          );
          break;
        case "tacho.driver.work.state.1":
          dispatch(
            slice.actions.updateTelemetryTachoAttributesStatus({
              flespiDeviceId,
              value: { driver1WorkState: message },
            })
          );
          break;
        case "tacho.driver.work.state.2":
          dispatch(
            slice.actions.updateTelemetryTachoAttributesStatus({
              flespiDeviceId,
              value: { driver2WorkState: message },
            })
          );
          break;
        case "tacho.driver.time.state.1":
          dispatch(
            slice.actions.updateTelemetryTachoAttributesStatus({
              flespiDeviceId,
              value: { driver1TimeState: message },
            })
          );
          break;
        case "tacho.driver.time.state.2":
          dispatch(
            slice.actions.updateTelemetryTachoAttributesStatus({
              flespiDeviceId,
              value: { driver2TimeState: message },
            })
          );
          break;
        case "tacho.driver.selected.activity.duration.1":
          dispatch(
            slice.actions.updateTelemetryTachoAttributesStatus({
              flespiDeviceId,
              value: { driver1SelectedActivityDuration: message },
            })
          );
          break;
        case "tacho.driver.selected.activity.duration.2":
          dispatch(
            slice.actions.updateTelemetryTachoAttributesStatus({
              flespiDeviceId,
              value: { driver2SelectedActivityDuration: message },
            })
          );
          break;
        case "tacho.driver.cumulative_driving.duration.1":
          dispatch(
            slice.actions.updateTelemetryTachoAttributesStatus({
              flespiDeviceId,
              value: { driver1CumulativeDrivingDuration: message },
            })
          );
          break;
        case "tacho.driver.cumulative_driving.duration.2":
          dispatch(
            slice.actions.updateTelemetryTachoAttributesStatus({
              flespiDeviceId,
              value: { driver2CumulativeDrivingDuration: message },
            })
          );
          break;
        case "tacho.driver.continuous_driving.duration.1":
          dispatch(
            slice.actions.updateTelemetryTachoAttributesStatus({
              flespiDeviceId,
              value: { driver1ContinuousDrivingDuration: message },
            })
          );
          break;
        case "tacho.driver.continuous_driving.duration.2":
          dispatch(
            slice.actions.updateTelemetryTachoAttributesStatus({
              flespiDeviceId,
              value: { driver2ContinuousDrivingDuration: message },
            })
          );
          break;
        case "tacho.driver.cumulative_break.duration.1":
          dispatch(
            slice.actions.updateTelemetryTachoAttributesStatus({
              flespiDeviceId,
              value: { driver1CumulativeBreakDuration: message },
            })
          );
          break;
        case "tacho.driver.cumulative_break.duration.2":
          dispatch(
            slice.actions.updateTelemetryTachoAttributesStatus({
              flespiDeviceId,
              value: { driver2CumulativeBreakDuration: message },
            })
          );
          break;
      }
    }
  };

type UpdateTelemetryPositionPayload = {
  position: { lat: number; lon: number };
  flespiDeviceId: string;
};

const slice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    updateTelemetryPosition(
      state,
      action: PayloadAction<UpdateTelemetryPositionPayload>
    ) {
      const { flespiDeviceId, position } = action.payload;
      state.telemetry[flespiDeviceId] = {
        ...state.telemetry[flespiDeviceId],
        id: flespiDeviceId,
        position,
      };
    },
    updateTelemetryTachoAttributesStatus(
      state,
      action: PayloadAction<{
        flespiDeviceId: string;
        value: {
          vehicleMileage?: string;
          driver1Status?: boolean;
          driver1ContinuousDrivingDuration?: number;
          driver1CumulativeBreakDuration?: number;
          driver1CumulativeDrivingDuration?: number;
          driver1SelectedActivityDuration?: number;
          driver1TimeState?: string;
          driver1WorkState?: string;
          driver2Status?: boolean;
          driver2ContinuousDrivingDuration?: number;
          driver2CumulativeBreakDuration?: number;
          driver2CumulativeDrivingDuration?: number;
          driver2SelectedActivityDuration?: number;
          driver2TimeState?: string;
          driver2WorkState?: string;
        };
      }>
    ) {
      const { flespiDeviceId, value } = action.payload;
      if (!state.telemetry[flespiDeviceId]?.tachoStats) {
        state.telemetry[flespiDeviceId] = {
          ...state.telemetry[flespiDeviceId],
          tachoStats: {
            driver1: { showStatus: false },
            driver2: { showStatus: false },
          },
        };
      }
      const tachoStats = state.telemetry[flespiDeviceId].tachoStats!;

      if (typeof value.driver1Status === "boolean") {
        tachoStats.driver1.showStatus = value.driver1Status;
      }
      if (typeof value.driver2Status === "boolean") {
        tachoStats.driver2.showStatus = value.driver2Status;
      }
      if (value.driver1WorkState) {
        tachoStats.driver1 = {
          ...tachoStats.driver1,
          workState: value.driver1WorkState,
        };
      }
      if (value.driver1TimeState) {
        tachoStats.driver1 = {
          ...tachoStats.driver1,
          timeState: value.driver1TimeState,
        };
      }
      if (value.driver1SelectedActivityDuration) {
        tachoStats.driver1 = {
          ...tachoStats.driver1,
          selectedActivityDuration: value.driver1SelectedActivityDuration,
        };
      }
      if (value.driver1CumulativeDrivingDuration) {
        tachoStats.driver1 = {
          ...tachoStats.driver1,
          cumulativeDrivingDuration: value.driver1CumulativeDrivingDuration,
        };
      }
      if (value.driver1ContinuousDrivingDuration) {
        tachoStats.driver1 = {
          ...tachoStats.driver1,
          continuousDrivingDuration: value.driver1ContinuousDrivingDuration,
        };
      }
      if (value.driver1CumulativeBreakDuration) {
        tachoStats.driver1 = {
          ...tachoStats.driver1,
          cumulativeBreakDuration: value.driver1CumulativeBreakDuration,
        };
      }
      if (value.driver2WorkState) {
        tachoStats.driver2 = {
          ...tachoStats.driver2,
          workState: value.driver2WorkState,
        };
      }
      if (value.driver2TimeState) {
        tachoStats.driver2 = {
          ...tachoStats.driver2,
          timeState: value.driver2TimeState,
        };
      }
      if (value.driver2SelectedActivityDuration) {
        tachoStats.driver2 = {
          ...tachoStats.driver2,
          selectedActivityDuration: value.driver2SelectedActivityDuration,
        };
      }
      if (value.driver2CumulativeDrivingDuration) {
        tachoStats.driver2 = {
          ...tachoStats.driver2,
          cumulativeDrivingDuration: value.driver2CumulativeDrivingDuration,
        };
      }
      if (value.driver2ContinuousDrivingDuration) {
        tachoStats.driver2 = {
          ...tachoStats.driver2,
          continuousDrivingDuration: value.driver2ContinuousDrivingDuration,
        };
      }
      if (value.driver2CumulativeBreakDuration) {
        tachoStats.driver2 = {
          ...tachoStats.driver2,
          cumulativeBreakDuration: value.driver2CumulativeBreakDuration,
        };
      }
      if (value.vehicleMileage) {
        tachoStats.vehicleMileage = value.vehicleMileage;
      }

      state.telemetry[flespiDeviceId] = {
        ...state.telemetry[flespiDeviceId],
        tachoStats,
      };
    },
    updateTelemetryIgnitionStatus(
      state,
      action: PayloadAction<{ flespiDeviceId: string; value: boolean }>
    ) {
      const { flespiDeviceId, value } = action.payload;
      state.telemetry[flespiDeviceId] = {
        ...state.telemetry[flespiDeviceId],
        ignitionStatus: { value },
      };
    },
    updateTelemetryMovementStatus(
      state,
      action: PayloadAction<{ flespiDeviceId: string; value: boolean }>
    ) {
      const { flespiDeviceId, value } = action.payload;
      state.telemetry[flespiDeviceId] = {
        ...state.telemetry[flespiDeviceId],
        movementStatus: { value },
      };
    },
    flespiTelemetryMessageReceived(
      state,
      action: PayloadAction<{ topic: string; message: any }>
    ) {
      const { topic, message } = action.payload;
      const matches = telemetryRegex.exec(topic);
      if (matches) {
        const deviceId = matches.groups!["deviceId"];
        switch (matches.groups!["key"]) {
          case "position": {
            state.telemetry[deviceId] = {
              ...state.telemetry[deviceId],
              id: deviceId,
              position: { lat: message.latitude, lon: message.longitude },
            };
          }
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadTrackerDevices.fulfilled, (state, action) => {
        const trackerDevices = action.payload;
        state.entities = keyBy(trackerDevices, "id");
        state.ids = trackerDevices.map((x) => x.id);
      })
      .addCase(loadFlespiToken.fulfilled, (state, action) => {
        state.token = action.payload;
      })
      // .addCase(loadDataFromTrackerDevice.fulfilled, (state, action) => {
      //   const message = action.payload;
      //   const deviceFlespiId: number = message["device.id"];
      //
      //   state.devicesData[deviceFlespiId] = message;
      // })
      .addCase(lookupDevicePositionWithTimestamp.fulfilled, (state, action) => {
        if (!action.payload) {
          return;
        }
        const { flespiId, timestamp, place } = action.payload;
        const telemetry = state.telemetry[flespiId];
        if (telemetry) {
          if (
            telemetry.location?.updatedAt &&
            telemetry.location.updatedAt > timestamp
          ) {
            return;
          }
          // Always true, but nevermind.
          telemetry.location = {
            updatedAt: timestamp,
            place,
          };
        }
      });
  },
});

export default slice.reducer;
