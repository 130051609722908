import * as t from "io-ts";
import { nullable } from "dora-contracts";

export const invoiceT = t.type({
  id: t.string,
  clientId: t.string,
  total: t.string,
  sentToEconomic: t.boolean,
  otherRef: nullable(t.string),
});

export const invoicesT = t.array(invoiceT);
export type Invoice = t.TypeOf<typeof invoiceT>;
