import { RootState } from "../../../redux-store";

export const selectTeams = (state: RootState) => state.data.teams.teams;

export const selectTeam = (teamId: string) => (state: RootState) =>
  state.data.teams.teams.find((x) => x.id === teamId);

export const selectTeamDrivers = (teamId: string) => (state: RootState) =>
  state.data.teams.teamsDrivers[teamId];

export const selectTeamVehicles = (teamId: string) => (state: RootState) =>
  state.data.teams.teamsVehicles[teamId];
