import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { UFDoraTextInput } from "../../../../Components/Toolkit/DoraTextInput";
import DoraButton from "../../../../Components/Toolkit/DoraButton";
import { useAppDispatch } from "../../../../redux-store";
import { FormProvider, useForm } from "react-hook-form";
import { DateTime } from "luxon";
import { addDriverSchedule } from "../../../../ducks/data/drivers";
import { UFDoraDateTimePicker } from "../../../../Components/Toolkit/DoraDateTimePicker";

export const DriverScheduleAddInterval = (props: {
  driverId: string;
  isOpen: boolean;
  onTitleClick: () => void;
  onSubmit: () => void;
}) => {
  const { t } = useTranslation("driverSchedule");
  const { driverId, isOpen } = props;
  const [saving, setSaving] = useState(false);
  const dispatch = useAppDispatch();

  const formMethods = useForm<{
    startDate: DateTime;
    endDate: DateTime;
    absenceNote: string;
  }>({
    defaultValues: {
      startDate: DateTime.now().startOf("minute"),
      endDate: DateTime.now().startOf("minute"),
      absenceNote: "",
    },
  });

  const { control, handleSubmit, reset, register, watch, setValue, getValues } =
    formMethods;

  const startDateWatch = watch("startDate");

  useEffect(() => {
    const endDateValue = getValues("endDate");
    if (endDateValue.toMillis() < startDateWatch.toMillis()) {
      setValue("endDate", startDateWatch);
    }
  }, [startDateWatch, setValue, getValues]);

  const onSubmit = () => {
    handleSubmit((data) => {
      setSaving(true);
      dispatch(
        addDriverSchedule({
          driverId,
          schedule: {
            type: "INTERVAL",
            weekdays: null,
            recurrentTimeInterval: null,
            startDate: data.startDate.startOf("minute").toISO({
              includeOffset: false,
              suppressSeconds: true,
              suppressMilliseconds: true,
            }),
            endDate: data.endDate.startOf("minute").toISO({
              includeOffset: false,
              suppressSeconds: true,
              suppressMilliseconds: true,
            }),
            absenceNote: data.absenceNote,
          },
        })
      )
        .unwrap()
        .then(() => {
          reset();
          props.onSubmit();
        })
        .finally(() => {
          setSaving(false);
        });
    })();
  };

  return (
    <div>
      <div className="driver-schedule-dialog__section-title">
        <div>{t("addInterval")}</div>
        <IconButton onClick={props.onTitleClick} style={{ fontSize: 16 }}>
          <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
        </IconButton>
      </div>
      {isOpen && (
        <div className="driver-schedule-dialog__section-content">
          <FormProvider {...formMethods}>
            <div className="flex gap-2 justify-between items-center">
              <div>
                <div className="driver-schedule-dialog__section-content-text">
                  {t("start")}
                </div>
                <UFDoraDateTimePicker control={control} name={"startDate"} />
              </div>
              <div>
                <div className="driver-schedule-dialog__section-content-text">
                  {t("end")}
                </div>
                <UFDoraDateTimePicker
                  minDateTime={startDateWatch}
                  control={control}
                  name={"endDate"}
                />
              </div>
            </div>
            <div className="mt-1">
              <div className="driver-schedule-dialog__section-content-text mb-1">
                {t("absenceNote")}
              </div>
              <UFDoraTextInput
                register={register("absenceNote")}
                placeholder={t("absenceNote")}
              />
            </div>
            <div className="mt-6 mb-5">
              <DoraButton
                variant="primary-filled"
                disabled={saving}
                onClick={onSubmit}
              >
                {t("save")}
              </DoraButton>
            </div>
          </FormProvider>
        </div>
      )}
    </div>
  );
};
